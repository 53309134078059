
.ButtonDeleteClassTransportView .MuiButtonBase-root{
    background: rgba(255,255,255,0);
    box-shadow:none;
    width: auto;
    height:auto;
}
.ButtonDeleteClassTransportView .MuiButtonBase-root:hover{
    background: rgba(149,184,238,0.3);
    color: red;
}
.ButtonRedactorClassTransportView .MuiButtonBase-root{
    width: auto;
    height:auto;
}
.ButtonRedactorClassTransportView .MuiButtonBase-root:hover{
    background: rgba(149,184,238,0.3);
    border-radius: 25px;
    color: blue!important;
}