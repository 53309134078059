.office_wrapper .field_Text_Param{
    padding: 7px!important;
}
.office_wrapper .category_view svg {
    /*color: rgba(255,255, 255, 0.9)*/
}
.products_filters_above_table_products svg{
    /*color: rgba(255,255, 255, 0.9)*/
}

 /*начало для фильтров слева от таблицы товаров*/
.office_wrapper .products_filters{
    padding: 10px 0 7px 0px!important;
    /*border:rgba(255,255, 255, 0.5) 1px solid!important;*/
    border-radius: 5px!important;
    /*background-color: rgba(0,0,0,0.8)!important;*/
    /*margin-top: 3px!important;*/
    height: max-content!important;
    font-weight: bold!important;
}
.office_wrapper .field_Text_Param .MuiFormLabel-root{
    color: white;
}
.office_wrapper .products_filters_menu .MuiAccordion-root {
    margin: 16px 0 0 0!important;
}

.office_wrapper .products_filters_menu .MuiAccordionSummary-content {
    margin: 0!important;
}

.office_wrapper .products_filters_menu .MuiIconButton-root {
    padding: 0 12px!important;
}

.office_wrapper .products_filters_menu .MuiAccordionSummary-root {
    min-height: 30px!important;
}

.office_wrapper .products_filters_menu .MuiAccordionSummary-root:hover:not(.Mui-disabled){
    height: auto;
}
.office_wrapper .products_filters_menu .MuiAccordionSummary-content.Mui-expanded{
    margin: 0px!important;
}

.office_wrapper .product_filter{
    height: max-content!important;
    padding: 0px 5px 0px 3px!important;
    font-weight: bold!important;
}

.office_wrapper .product_filter .MuiAccordionSummary-content {
    margin: 12px 0!important;
}

.office_wrapper .product_filter .MuiAccordionSummary-root {
    min-height: 48px!important;
}

.office_wrapper .products_filters .MuiAccordionDetails-root{
    padding: 0px 5px 0px!important;
}
.office_wrapper .products_filters .flex{
    margin-top: 0px!important;
}

.office_wrapper .products_filters .MuiFormControlLabel-root{
    margin-left: 0px !important;
}
/*.office_wrapper .products_filters .product_filter.MuiAccordionSummary-root:hover:not(.Mui-disabled){*/
/*    padding: 0px 20px 0px 10px;*/
/*    border-bottom: dot-dash !important;*/
/*    border-bottom: rgba(255, 255, 255, 0.5) !important;*/

/*}*/
.office_wrapper .product_filter{
font-weight: bold!important;
}

.office_wrapper .product_filters_buttons {
    margin-top: 5px;
}

.office_wrapper .button_delete_all_filters{
    width: 212px!important;
    margin: 0px 0px 1px 0px!important;
    padding: 4px!important;
    border:rgba(255,255, 255, 0.5) 2px solid!important;
    background-color:rgba(3, 30, 255, 0.7)!important;
    border-radius: 5px!important;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    color: rgba(255,255, 255, 0.85)!important;
    font-weight: bold!important;
    text-align: center;
}
.office_wrapper .button_delete_all_filters:hover{
    background-color:blue!important;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    color: rgb(246, 36, 89)!important;
}
/*окончание для фильтров слева от таблицы товаров*/

/*начало для фильтров над таблицей товаров*/
.office_wrapper .products_filters_above_table_products_button{
    margin: 5px 6px 1px 0;
}
.office_wrapper .products_filters_above_table_products{
    /*border:rgba(255,255, 255, 0.5) 2px solid!important;*/
    border-radius: 4px!important;
    /*background-color: rgba(0,0,0,0.8)!important;*/
    margin: 0!important;
    padding: 3px!important;
}
.office_wrapper .icon_delete_products_filters_above_table_products{
    /*border:rgba(255,255, 255, 0.5) 2px solid!important;*/
    border-radius: 4px!important;
    /*background-color: rgba(0,0,0,0.8)!important;*/
    margin: 0 0 0 2px!important;
    padding: 3px!important;
}
.office_wrapper .icon_delete_products_filters_above_table_products:hover{
    /*border:rgba(255,255, 255, 0.4) 2px solid!important;*/
    /*background-color: rgba(0,0,0,0.8)!important;*/
    color: rgb(246, 36, 89)!important;
}
/*окончание для фильтров над таблицей товаров*/

.office_wrapper .product_search_office .MuiInputLabel-shrink{
    transform: translate(14px, -15px) scale(0.9) !important;
    /*color: yellow!important;*/
}

.office_wrapper .product_search_office .MuiOutlinedInput-root{
    border:rgba(255,255, 255, 0.5) 2px solid!important;
    /*background-color: rgba(0,0,0,0.8)!important;*/
}

.office_wrapper .product_search_office .MuiOutlinedInput-notchedOutline{
    /*border: 0;*/
}

.office_wrapper .MuiFormControl-root{
flex-grow: 0.3;
    }

.office_wrapper .searchFiled{
    /*background-color: rgba(0,0,0,0.9) !important;*/
}
.office_wrapper .buttonSearchProduct_office svg{
    color: rgba(0,0,0,0.4);
}
.office_wrapper .buttonSearchProduct_office{
    border:rgba(0,0,0, 0.2) 1px solid!important;
    background-color: rgba(248,248,248,1)!important;
    border-radius: 5px;
    margin: 2px 1px 2px 1px;
}
.office_wrapper .buttonSearchProduct_office:active svg{
    color: rgba(0,0,0,0.6);
}

.office_wrapper .buttonSearchProduct_office:active{
    /*border:rgba(255,255, 255, 0.3) 2px solid!important;*/
    background-color: rgba(240,240,240,1)!important;
    color: rgba(255,255, 255, 0.5)!important;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
}
.office_wrapper .buttonGreen_Product{
    padding:3px!important;
    margin:0px!important;
    border: rgba(0, 0, 0, 0.9) 2px solid!important;
    background-color:darkgreen!important;
    border-radius: 8px!important;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    color: black!important;
    font-weight: bold!important;
}
.office_wrapper .buttonGreen_Product:hover {
    background:yellowgreen!important;
    color: white!important;
}
.office_wrapper .buttonBlue_View_Product{
    padding:3px!important;
    margin:0px!important;
    border: rgba(0, 0, 0, 0.9) 2px solid!important;
    background-color:blue!important;
    border-radius: 8px!important;
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    color: black!important;
    font-weight: bold!important;
}
.office_wrapper .buttonBlue_View_Product:hover {
    background:dodgerblue!important;
    color: white!important;
}
.office_wrapper .button_Select_Text_Product{
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
    cursor: pointer;
    text-decoration-line: underline!important;
}
.office_wrapper .button_Select_Text_Product:hover {
    /*background-color:#464646!important;*/
    /*color: white!important;*/

}

.office_wrapper .product_filter_element {
    padding: 0 0 0 15px!important;
}