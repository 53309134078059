body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: auto!important;
  padding: 0 !important;
    height:100vh;
}

#root {
    overflow: auto;
}

.main-wrapper div,
.main-wrapper li,
.main-wrapper header,
.main-wrapper p,
.main-wrapper button,
.main-wrapper h1,
.main-wrapper th {
  background-color: rgba(0,0,0,0);
}

 .MuiDialog-root {
     background-color: rgba(0,0,0,0.7) !important;
 }

.main-wrapper article,
.main-wrapper textarea,
.main-wrapper input,
.main-wrapper li,
.main-wrapper header,
.main-wrapper p,
.main-wrapper button,
.main-wrapper h1,
.main-wrapper h2,
.main-wrapper h3,
.main-wrapper h4,
.main-wrapper h5,
.main-wrapper h6,
.main-wrapper th,
.main-wrapper label {
  color: white;
}

th, p {
  font-size: inherit !important;
}

div a {
    text-decoration: none !important;
    color: inherit;
}

a {
  text-decoration: none !important;
    color: white;
}

a:hover {
    text-decoration: none !important;
    color: rgba(255,255,255, 0.8);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.flex {
  display: flex;
}

.flex_wrap {
    display: flex;
    flex-wrap: wrap;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.left_position{

}

.full_auto_play_slider{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textfield_white_border div {
  border-color: white!important;
}

.padding_in_div_2px > div {
    padding: 2px;
}

.selected {
    font-weight: 800!important;
}

*::-webkit-scrollbar {
    width: 10px;
}
*::-webkit-scrollbar-thumb {
    background-color: #D0D3D4;
    border-radius: 20px;
}

.pointer {
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.pointer:hover {
    cursor: pointer;
    background-color: rgba(0,0,0,0.5) !important;
}
.pointerForLeftMenu {
    -webkit-transition-duration: 0.4s;
    transition-duration: 0.4s;
}

.pointerForLeftMenu:hover {
    cursor: pointer;
    color: red;
    /*background-color: rgba(0,191,255,0.25) !important;*/
}
.pointerForCategoryOffice{
    cursor: pointer;
}
.pointerForCategoryOffice:hover{
    /*background-color: rgba(149,184,238,0.2);*/
    color: red;
}
.white_svg svg {
    color: white!important;
}

.divider {
    width: 100%;
    border-bottom: 2px dotted rgba(255, 255, 255, 0.5);
    margin-bottom: 25px;
}

body > .MuiAutocomplete-popper {
    background-color: rgba(30, 30, 30, 0.99)!important;
}

body > .MuiPopover-root > .MuiPaper-root {
    background-color: rgba(30, 30, 30, 0.99)!important;
}

.cursor_auto {
    cursor: auto!important;
}

.text_color_sandybrown {
    color: sandybrown!important;
}

.ReactModal__Overlay {
    z-index: 1400!important;
    background-color: rgba(0,0,0,0.8)!important;
}

.background_black {
    background-color: black !important;
}

.background_white {
    background-color: white !important;
}

.background_invisible {
    background-color: rgba(0,0,0,0) !important;
}