.dialogWindow{
    background-color:rgba(45,44,40,0.95)!important;
    border: rgba(255,255,255,0.5) 2px solid;

}

.modal_window div,
.modal_window button {
    color: white;
}

.modal_window div,
.modal_window li,
.modal_window header,
.modal_window p,
.modal_window button,
.modal_window h1,
.modal_window th {
    background-color: rgba(0,0,0,0);
}